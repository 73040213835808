<template>
  <div class="wrap x-x" id="wrap">
    <div class="left">
      <!-- 左边树装选择 -->
      <categoryTreeCard
        categoryTreeTitle="会员级别"
        @treeNodeId="getTreeNodeId"
        @appendTree="appendTree"
        @editTree="editTree"
        @removeTree="removeTree"
        :isEditBtn="true"
        :boxHeight="boxHeight"
        :boxInternalHeight="boxInternalHeight"
        :categoryTreeOptions="vipCardTreeOptions"
        :defaultProps="defaultProps"
      />
    </div>
    <div class="right">
      <TablePage
        ref="tablePage"
        class="tablepage"
        v-model="options"
        @handleEvent="handleEvent"
      >
      </TablePage>
    </div>

    <!-- 新增/编辑会员对话框 -->
    <add-dialog ref="addDialog" @getList="$refs.tablePage.getList()" />
    <!-- 批量新增会员对话框 -->
    <batch-dialog ref="batchDialog" @getList="$refs.tablePage.getList()" />
    <!-- 批量编辑会员对话框 -->
    <batch-edit ref="batchEdit" @getList="$refs.tablePage.getList()" />
    <!-- 操作会员级别弹窗 -->
    <VipLevelDialog ref="vipLevelDialog" @getList="getVipLevelData"></VipLevelDialog>
    <!-- 导入 -->
    <!-- <to-channel
      class="ToChannel"
      :visible.sync="openToChannel"
      :downloadFile="downloadFile"
      @getFileUrl="getFileUrl"
      @removeFile="removeFile"
      @upload="uploadFile"
    ></to-channel> -->
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import ToChannel from "@/components/dialogTemplate/toChannel/index.vue";
import VipLevelDialog from "@/views/vip/base/level/components/vipLevelDialog.vue";
import categoryTreeCard from "@/views/components/categoryTreeCard"; //选择类别
import TablePage from "@/components/tablePage";
import { getToken } from "@/utils/auth";
// 导入模板接口importTemplate
import { importTemplate } from "@/api/system/user"; //导入接口
import addDialog from "./components/addDialog"; //新增框
import batchDialog from "./components/batchDialog"; //批量新增框
import batchEdit from "./components/batchEdit"; //批量编辑框
import { listlevel, dellevel } from "@/api/vip/base/level"; //会员等级
import {
  listVipCard,
  delVipCard,
  getVipCardDetail,
  restoreVipCard,
  vipInfoImport,
  queryVipInfoImport,
  vipInfoSummary,
  vipInfoExport,
  queryVipInfoExport,
} from "@/api/vip/base/vipCard";
import { getTimeDate } from "@/utils/newDate/index.js"; //年月日时分秒
import { deepCopy } from "@/utils";
import { vipParamListAPI } from "@/api/shop/setup/posParam/vipParam";
export default {
  name: "vipCard",
  dicts: ["vip_card_status", "sys_user_sex", "App_del_flag"],
  components: {
    addDialog,
    batchDialog,
    batchEdit,
    categoryTreeCard,
    TablePage,
    VipLevelDialog,
    ToChannel,
    Dialog,
  },
  data() {
    return {
      vipBrandId: undefined, //会员品牌
      openToChannel: false, //会员资料导入开关
      downloadFile: {
        // 下载模板
        fileName: "会员资料导入模板.xlsx", // 文件名
        downloadUrl:
          "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-09-27/隼云商品导入示范文件.xlsx", // 下载地址
      },
      //树型组件参数
      defaultProps: {
        id: "vipLevelId",
        label: "vipLevelName",
      },
      options: {
        virtualScroll: true, // 虚拟滚动
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listVipCard,
        getSummaryApi: vipInfoSummary,
        // exportOption: {},
        body: {},
        defaultBody: {},
        title: "会员级别列表",
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "vipId",
        //搜索
        search: [
          {
            label: "创建日期",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          this.$select({
            key: "viplevel",
            option: {
              filter: "vipLevelIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "vip_card_status",
            option: {
              filter: "cardStatuss",
              seniorSearch: true,
              labelWidth: 90,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "add-batch",
            label: "批量新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "batchEdit",
            label: "批量编辑",
            icon: "el-icon-edit-outline",
            type: "primary",
            disabled: () => !this.options?.check?.length || !this.vipBrandId,
          },
          {
            click: "vipLedger",
            label: "会员账本",
            type: "primary",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中会员吗？",
            btnType: "dropdown",
            trigger: "click", // 要求
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: restoreVipCard,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "importFile",
            right: true,
            label: "导入",
            icon: "el-icon-download",
            type: "",
          },
          {
            click: "exportFile",
            right: true,
            label: "导出",
            icon: "el-icon-upload2",
            type: "",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
            fixed: true,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "cardStatusName",
            label: "状态",
            minWidth: 120,
            align: "center",
            style: (propValue) => {
              return `color: ${propValue != "正常" ? "#ff4949" : ""} `;
            },
          },
          {
            prop: "vipSourceTypeName",
            label: "会员来源",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopName",
            label: "开卡门店",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "usableCreditMoney",
            label: "信用额度",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "totalUsableMoney",
            label: "卡内总余额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "allFillMoney",
            label: "累计充值金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "allConsumeMoney",
            label: "累计消费金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "allPayMoney",
            label: "累计支付金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "usableFillMoney",
            label: "可用充值金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "allLargessMoney",
            label: "累计赠送金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "usableLargessMoney",
            label: "可用赠送金额",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "allScore",
            label: "累计积分",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "usableScore",
            label: "可用积分",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "creditMoney",
            label: "信用额度",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "fillCount",
            label: "充值次数",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "consumeCount",
            label: "消费次数",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "payCount",
            label: "支付次数",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "scoreCount",
            label: "积分次数",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "isCardEmitedName",
            label: "发放",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "zjm",
            label: "助记码",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "开始使用时间",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "lastUseTime",
            label: "最近使用时间",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "sexName",
            label: "性别",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "birthday",
            label: "会员生日",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "address",
            label: "地址",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "createTime",
            label: "激活时间",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
            align: "center",
          },
        ],
        summary: [
          "usableCreditMoney",
          "allBalance",
          "allFillMoney",
          "usableFillMoney",
          "allLargessMoney",
          "usableLargessMoney",
          "allScore",
          "usableScore",
          "creditMoney",
          "fillCount",
          "payCount",
          "scoreCount",
          "allPayMoney",
          "allConsumeMoney",
          "totalUsableMoney",
        ],
        list: [],
      },
      //弹窗配置
      dialogOptions: {
        curType: "", //当前类型(门店/商品/类别/品牌)
        title: "文件导入",
        width: 600,
        show: false,
        type: "ImportFile",
      },
      //当前会员级别
      curVipLevel: undefined, //当前会员级别
      vivwH: 0, //页面高度
      //高级搜索图标
      icon: "el-icon-caret-bottom",
      //是否显示高级搜索
      showHigh: false,
      isBirthday: undefined, //会员生日
      datetime: undefined, //单据查询时间
      title: "", // 弹出层标题
      ids: [], // 选中数组
      name: [], //选中数组的名称
      total: 0, // 总条数
      // 是否显示弹出层
      open: false,
      loading: false, //加载框
      tableData: [], //表格
      vipCardTreeOptions: [], //会员等级tree

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/system/user/importData",
      },
    };
  },
  created() {
    this.getVipLevelData();
  },
  computed: {
    //tree组件自定义高度
    boxHeight() {
      return this.vivwH - 10;
    },
    boxInternalHeight() {
      return this.vivwH - 83;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
    });
  },
  methods: {
    //会员资料导入
    uploadFile(fromData) {
      console.log(fromData, "fromData");
      // uploadFileRequest(fromData)
      //   .then(res => {
      //     this.$message.success('上传成功')
      //     this.openToChannel = false
      //     this.getList()
      //   })
      //   .catch(error => {
      //     console.log('error', error)
      //     this.$message.error('导入商品资料失败')
      //     this.errorList = error.data.errorList.map(item => {
      //       return { errorInfo: item }
      //     })
      //     this.errVisible = true
      //   })
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
    },
    async handleEvent(type, row, e) {
      switch (type) {
        case "add":
          this.handleAdd();
          break;
        case "update":
          this.handleDetail(row);
          break;
        case "add-batch":
          this.batchHandleAdd();
          break;
        case "batchEdit":
          this.batchEdit();
          break;
        case "del":
          this.handleDelete();
          break;
        case "importFile": //会员导入
          this.dialogOptions = {
            curType: "", //当前类型(门店/商品/类别/品牌)
            title: "文件导入",
            width: 600,
            show: true,
            type: "ImportFile",
            data: {
              type: "vipInfo",
              monofile: true, //单文件上传
              hearderList: [
                //xlsx 表头字段关联
                { originalName: "会员卡号", curName: "会员卡号", rules: true },
                { originalName: "会员名称", curName: "会员名称", rules: true },
                { originalName: "手机", curName: "手机", rules: true },
                { originalName: "会员生日", curName: "会员生日" },
                { originalName: "级别编码", curName: "级别编码", rules: true },
                { originalName: "级别名称", curName: "级别名称", rules: true },
                { originalName: "性别", curName: "性别" },
                { originalName: "状态", curName: "状态" },
                { originalName: "可用充值金额", curName: "可用充值金额" },
                { originalName: "可用积分", curName: "可用积分" },
                { originalName: "地址", curName: "地址" },
                { originalName: "备注", curName: "备注" },
                { originalName: "累计充值金额", curName: "累计充值金额" },
                { originalName: "累计积分", curName: "累计积分" },
                { originalName: "公众号AppID", curName: "公众号AppID" },
                { originalName: "微信OpenID", curName: "微信OpenID" },
                { originalName: "微信卡包Code", curName: "微信卡包Code" },
                { originalName: "微信卡包ID", curName: "微信卡包ID" },
                { originalName: "IC卡物理卡号", curName: "IC卡物理卡号" },
                { originalName: "ID卡物理卡号", curName: "ID卡物理卡号" },
                { originalName: "地址", curName: "地址" },
                { originalName: "备注", curName: "备注" },
              ],
              uploadApi: vipInfoImport,
              queryUploadApi: queryVipInfoImport,
            },
          };
          break;
        case "exportFile": //会员导出管理员验证
          try {
            this.dialogOptions = {
              title: "管理员验证",
              width: 400,
              show: true,
              type: "VerificationAdmin",
            };
            // console.log(this.options,'什么鬼啊')
            // const { msg } = await vipInfoExport(this.options.body)
            // this.dialogOptions = {
            //   title: "文件导出",
            //   width: 400,
            //   show: true,
            //   type: "ExportFile",
            //   hideTitle: true,
            //   data: {
            //     exportOption: {
            //       getExportKeyApi: vipInfoExport,
            //       getExportKeyApiData: this.options.body,
            //       queryExportApi: queryVipInfoExport,
            //     },
            //   },
            // };
          } catch (err) {}
          // //导出方法
          // this.download(
          //   '/api/system/vip/base/vipcard/listExport',
          //   {
          //     ...this.options.defaultBody,
          //     ...this.options.pagination
          //   },
          //   `会员资料导出${getTimeDate()}.xlsx`
          // )
          break;
        case "exportFileSuccess":
          try {
            console.log("导出row", row);
            await this.$nextTick();
            this.dialogOptions = {
              title: "文件导出",
              width: 400,
              show: true,
              type: "ExportFile",
              hideTitle: true,
              data: {
                exportOption: {
                  getExportKeyApi: vipInfoExport,
                  getExportKeyApiData: {
                    ...this.options.body,
                    isEncryption: row, // 是否加密
                  },
                  queryExportApi: queryVipInfoExport,
                },
              },
            };
          } catch (err) {}
          break;
        case "vipLedger": //会员账本
          if (this.options?.check?.length != 1)
            return this.$message.error("只能查看单个会员的账本");
          this.$router.push({
            name: "VipLedger",
            query: {
              vipId: this.options?.check[0]?.vipId,
              type: "Update",
            },
          });
          break;
        default:
          break;
      }
    },
    //点击会员级别
    getTreeNodeId(value, row) {
      this.options.defaultBody.vipLevelId = value;
      this.vipBrandId = row?.vipBrandId;
      this.$refs.tablePage.getList();
    },
    //新增会员级别
    appendTree() {
      this.$refs.vipLevelDialog.initializaData();
    },
    //编辑会员级别
    editTree(data) {
      this.$refs.vipLevelDialog.initializaData(data.vipLevelId);
    },
    //删除会员级别
    async removeTree({ node, data }) {
      this.$confirm("确定删除此会员级别?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await dellevel([data.vipLevelId]);
            this.$message.success("删除会员级别");
            this.getVipLevelData();
          } catch (err) {}
        })
        .catch(() => {});
    },
    /** 删除按钮操作 */
    handleDelete() {
      let name = [];
      let ids = [];
      this.options.check.map((item) => {
        name.push(item.vipName);
        ids.push(item.vipId);
      });
      this.$modal
        .confirm('是否确认删除会员名称为"' + name.join(",") + '"的数据项？')
        .then(async () => {
          try {
            await delVipCard(ids);
            this.$modal.msgSuccess("删除成功");
            this.$refs.tablePage.getList();
          } catch (err) {}
        })
        .catch(() => {});
    },

    /** 批量新增按钮操作 */
    batchHandleAdd() {
      //重置表单
      this.$refs.batchDialog.reset();
      //修改新增对话框标题
      this.$refs.batchDialog.title = "批量新增会员资料";
      //会员级别
      let vipCardTreeOptions = deepCopy(this.vipCardTreeOptions);
      vipCardTreeOptions.shift();
      this.$refs.batchDialog.vipCardTreeOptions = vipCardTreeOptions;
      this.$refs.addDialog.changeVipLevel(this.options.defaultBody.vipLevelId);
      //弹出新增的对话框
      this.$refs.batchDialog.open = true;
    },
    //批量编辑
    batchEdit() {
      //重置表单
      this.$refs.batchEdit.reset();
      this.$refs.batchEdit.title = "批量编辑会员资料";
      //会员级别
      let vipCardTreeOptions = deepCopy(this.vipCardTreeOptions);
      vipCardTreeOptions.shift();
      let ids = this.options.check.map((item) => item.vipId);
      vipCardTreeOptions = vipCardTreeOptions.filter(
        (x) => x.vipBrandId == this.vipBrandId
      );
      this.$refs.batchEdit.vipCardTreeOptions = vipCardTreeOptions;
      this.$refs.batchEdit.vipForm.vipIds = ids;
      //弹出新增的对话框
      this.$refs.batchEdit.open = true;
    },
    /** 新增按钮操作 */
    handleAdd() {
      //重置表单
      this.$refs.addDialog.reset();
      //修改新增对话框标题
      this.$refs.addDialog.title = "新增会员资料";
      //会员级别
      let vipCardTreeOptions = deepCopy(this.vipCardTreeOptions);
      vipCardTreeOptions.shift();
      this.$refs.addDialog.vipCardTreeOptions = vipCardTreeOptions;
      this.$refs.addDialog.vipForm.vipLevelId = this.options.defaultBody.vipLevelId;
      this.$refs.addDialog.changeVipLevel(this.options.defaultBody.vipLevelId);
      //弹出新增的对话框
      this.$refs.addDialog.open = true;
    },
    //编辑按钮
    async handleDetail(row) {
      //重置表单
      this.$refs.addDialog.reset();
      this.$refs.addDialog.title = "编辑会员资料";
      //弹出新增的对话框
      this.$refs.addDialog.open = true;
      //获取品牌详情
      this.$refs.addDialog.detailLoading = true;
      //会员级别
      let vipCardTreeOptions = deepCopy(this.vipCardTreeOptions);
      vipCardTreeOptions.shift();
      this.$refs.addDialog.vipCardTreeOptions = vipCardTreeOptions;
      try {
        const res = await getVipCardDetail(row.vipId);
        const { data } = await vipParamListAPI(); //获取会员启用消费密码
        //处理会员生日农历
        if (res.data.isCnBirthday) {
          res.data.birthday = "L" + res.data.birthday;
        }
        this.$refs.addDialog.vipForm = {
          ...res.data,
          shop_VIP_CONFIG_PASSWORD_12: data.shop_VIP_CONFIG_PASSWORD_12,
          usePassWord: res.data.usePassWord ? "******" : "",
        };
        this.$refs.addDialog.cnCatch = true;
        this.$refs.addDialog.detailLoading = false;
      } catch (err) {}
    },
    //获取会员级别数据
    async getVipLevelData() {
      try {
        const { rows } = await listlevel({
          pageNum: 1,
          pageSize: 9999999,
        });
        this.vipCardTreeOptions = rows;
        if (this.vipCardTreeOptions.length >= 1) {
          //添加一个全部级别
          this.vipCardTreeOptions.unshift({
            vipLevelId: "",
            vipLevelName: "全部级别",
          });
        }
      } catch (err) {}
    },
    /** 导入按钮操作 */
    handleImport() {
      this.upload.title = "用户导入";
      this.upload.open = true;
    },
    /** 下载模板操作 */
    importTemplate() {
      importTemplate().then((response) => {
        this.download(response.msg);
      });
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(response.msg, "导入结果", { dangerouslyUseHTMLString: true });
      this.getList();
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "/api/system/vip/base/vipcard/export",
        {
          ...this.queryParams,
        },
        `post_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  ::v-deep .categoryTree {
    width: 240px !important;
  }
  ::v-deep .searchTree {
    width: 248px !important;
  }
  .right {
    width: calc(100% - 246px);
  }
}
::v-deep #table-page {
  padding: 0;
}
::v-deep .el-date-editor--datetimerange.el-input__inner {
  width: 300px !important;
}
::v-deep .select-all-filter {
  width: 200px;
}
</style>
